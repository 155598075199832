import {PagedList} from "../../types/core/PagedList";
import httpClient from "../../utils/httpClient";
import {Information} from "types/public-center/Information";
import {Member} from "../../types/member-center/Member";

const isLocalAPI = process.env.REACT_APP_LOCAL_API;
let SERVICE_NAME = "/member-center";
if (isLocalAPI === "true") {
    SERVICE_NAME = "";
}

export async function findInformationPageList(current: number, pageSize: number) {
    return httpClient.get<PagedList<Member>>(`${SERVICE_NAME}/api/v1/member/findPageList`, {
        params: {
            current,
            pageSize
        }
    })
}

export function getMemberById(id: string | undefined) {
    return httpClient.get<Member>(`${SERVICE_NAME}/api/v1/member/${id}`)
}

export async function updateMember(member: Partial<Information>) {
    return httpClient.put(`${SERVICE_NAME}/api/v1/member/${member.id}`, {
        data: member
    });
}