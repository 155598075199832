import {ComponentClass, FunctionComponent} from "react";
import {HttpClient} from "../httpclient";
import {AccessTokenResponse, User, UsernamePasswordAuthentication} from "./types";
import Cookies from "js-cookie";
import {AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY} from "./UAAConstants";
import {getTopDomain} from "./utils";

export interface UAAClientConfig {
    authServerUrl: string; // 认证服务器地址
    realm: string;  // 域
    clientKey: string; // 访问KEY
    // clientSecret: string; // 客户端秘钥
    LoadingComponent: ComponentClass<any> | FunctionComponent<any>;
}

export class UAAClient {
    private readonly _config: UAAClientConfig;
    private readonly _httpClient: HttpClient;

    constructor(config: UAAClientConfig) {
        this._config = config;
        this._httpClient = new HttpClient({
            baseURL: config.authServerUrl
        });
    }

    get config(): UAAClientConfig {
        return this._config;
    }
    // 获取认证码
    authorize() {
    }
    // 获取token
    async token(authentication: UsernamePasswordAuthentication) {
        return this._httpClient.post<AccessTokenResponse>("/api/v1/authorize/token", {
            data: authentication
        }).then(async (auth) => {
            let accessToken = Cookies.get(AUTH_ACCESS_TOKEN_KEY);
            let refreshToken = Cookies.get(AUTH_REFRESH_TOKEN_KEY);
            if (!accessToken || !refreshToken) {
                accessToken = auth.accessToken;
                refreshToken = auth.accessToken;
                if (!accessToken || !refreshToken) {
                    // console.log('获取TOKEN失败')
                    throw new Error('获取TOKEN失败');
                }
                const topDomain = getTopDomain();
                Cookies.set(AUTH_ACCESS_TOKEN_KEY, accessToken, {
                    domain: topDomain,
                    path: '/'
                });
                Cookies.set(AUTH_REFRESH_TOKEN_KEY, refreshToken, {
                    domain: topDomain,
                    path: '/'
                });
            }
        })
    }
    // 刷新token
    async refreshToken() {
        // const tokenStore = this._config.tokenStore;
        // const token = tokenStore.retrieveToken();
        const accessToken = Cookies.get(AUTH_ACCESS_TOKEN_KEY);
        const refreshToken = Cookies.get(AUTH_REFRESH_TOKEN_KEY);
        await this._httpClient.post<AccessTokenResponse>('/api/v1/authorize/token/refresh', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            params: {
                "refreshToken": refreshToken
            }
        }).then(auth => {
            // 存储token
            // tokenStore.storeToken(value)
            debugger
            let accessToken = Cookies.get(AUTH_ACCESS_TOKEN_KEY);
            let refreshToken = Cookies.get(AUTH_REFRESH_TOKEN_KEY);
            if (!accessToken || !refreshToken) {
                accessToken = auth.accessToken;
                refreshToken = auth.accessToken;
                if (!accessToken || !refreshToken) {
                    // console.log('refresh token fail');
                    throw new Error('刷新TOKEN失败');
                }
                const topDomain = getTopDomain();
                Cookies.set(AUTH_ACCESS_TOKEN_KEY, accessToken, {
                    domain: topDomain,
                    path: '/'
                });
                Cookies.set(AUTH_REFRESH_TOKEN_KEY, refreshToken, {
                    domain: topDomain,
                    path: '/'
                });
            }
        })

    }
    // 登录
    login() {
        window.location.href = this._config.authServerUrl + "?redirect_uri=" + encodeURIComponent(window.location.href);
    }
    // 退出
    async logout() {
        /*const accessToken = Cookies.get(AUTH_ACCESS_TOKEN_KEY);
        return this._httpClient.post("/api/v1/authorize/logout", {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }).catch(error => {
            // no handler error
        });*/
        this.clearToken();
    }
    // 检查会话
    async checkSessionIframe() {
    }
    // 获取用户信息
    async userInfo() {
        // fetch user
        // const token = this._config.tokenStore.retrieveToken();
        const accessToken = Cookies.get(AUTH_ACCESS_TOKEN_KEY);
        return this._httpClient.get<User>("/api/v1/user/me", {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            params: {
                clientKey: this._config.clientKey
            }
        })
    }
    retrieveAccessToken(): string | undefined {
        // return this._config.tokenStore.retrieveToken();
        return Cookies.get(AUTH_ACCESS_TOKEN_KEY);
    }
    private clearToken() {
        // this._config.tokenStore.removeToken();
        const topDomain = getTopDomain();
        // this._config.tokenStore.removeToken();
        Cookies.remove(AUTH_ACCESS_TOKEN_KEY, {
            domain: topDomain,
            path: '/'
        });
        Cookies.remove(AUTH_REFRESH_TOKEN_KEY, {
            domain: topDomain,
            path: '/'
        });
    }
}