import {PagedList} from "../../types/core/PagedList";
import httpClient from "../../utils/httpClient";
import {Information} from "types/public-center/Information";

const isLocalAPI = process.env.REACT_APP_LOCAL_API;
let SERVICE_NAME = "/publish-center";
if (isLocalAPI === "true") {
    // export const SERVICE_NAME = "/public-center";
    SERVICE_NAME = "";
}

export async function findInformationPageList(current: number, pageSize: number) {
    return httpClient.get<PagedList<Information>>(`${SERVICE_NAME}/api/v1/information/findPageList`, {
        params: {
            current,
            pageSize
        }
    })
}

export function getFootpathById(informationId: string | undefined) {
    return httpClient.get<Information>(`${SERVICE_NAME}/api/v1/information/${informationId}`)
}

export async function saveInformation(information: Partial<Information>) {
    return httpClient.post('${SERVICE_NAME}/api/v1/information', {
        data: information
    });
}

export async function updateInformation(information: Partial<Information>) {
    return httpClient.put(`${SERVICE_NAME}/api/v1/information/${information.id}`, {
        data: information
    });
}

export async function deleteInformation(informationId: string | undefined) {
    return httpClient.delete(`${SERVICE_NAME}/api/v1/information/${informationId}`);
}