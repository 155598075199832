import {UAAClient} from "uaa-js"
import {Loading} from "@alifd/next";
import React from "react";

// Define UAA API Base Url
const uaaBaseURL = process.env.REACT_APP_UAA_BASE_URL;

const uaaClient = new UAAClient({
    authServerUrl: `${uaaBaseURL}`,
    realm: "master",
    clientKey: "runway",
    LoadingComponent: () => {
        return (
            <Loading fullScreen={true}/>
        )
    }
});

export default uaaClient