import React from "react";
import {Dialog} from "@alifd/next";
import styled from "@emotion/styled";

type DialogProps = React.ComponentProps<typeof Dialog>

export interface DialogContainerProps extends DialogProps {
    /**
     * 对话框的宽度样式属性
     */
    width?: string;
}

const DialogContainer: React.FC<DialogContainerProps> = (props) => {
    const { width, children, ...restPros } = props;
    return (
        <Dialog {...restPros} style={{width: width || '60rem'}}>
            {children}
        </Dialog>
    );
}

/*
const Container = styled(Dialog)`
    width: ${props => props.width || '60rem'};
`
*/

export default DialogContainer