import React, {useState} from "react";
import {Navigate, NavLink, Route, Routes, useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import {PrivateRoute, useAuthz} from "contexts/UAAContext";
import Overview from "./pages/Overview";
import {Avatar, Dropdown, Icon, Menu, Nav, Shell} from "@alifd/next";
import NotFound from "pages/NotFound";
import {useSelectedNavPaths} from "./hooks/useSelectedNavPaths";
import MemberManagement from "./pages/MemberManagement";
import InformationManagement from "./pages/InformationManagement";
import FootpathManagement from "./pages/FootpathManagement";
import RunStatD from "./pages/RunStatD";
import FootpathDetail from "./pages/FootpathManagement/FootpathDetail";
import CapureDetail from "./pages/RunStatD/CapureDetail";
import MemberDetail from "./pages/MemberManagement/MemberDetail";
import FaIcon from "./components/FaIcon";

const AuthenticatedApp = () => {
    // 路由
    // useRoutes([]);

    const navigate = useNavigate();
    // nav
    const selectedNavPaths = useSelectedNavPaths();
    //
    const {user, logout} = useAuthz();
    // const {hasRole} = useAuthorize();

    const [navcollapse, setNavcollapse] = useState(false);
    const onNavCollapseChange = (collapse?: boolean) => {
        setNavcollapse(collapse || false);
        return {}
    };

    return (
        <Shell
            className={"iframe-hack"}
            device={"desktop"}
            style={{border: "1px solid #eee"}}
        >
            <Shell.Branding>
                <div className="rectangular"></div>
                <span style={{marginLeft: 10}}>智慧步道</span>
            </Shell.Branding>

            <Shell.Navigation direction="hoz" align={"left"}>
            </Shell.Navigation>

            <Shell.Action>
                <Dropdown
                    trigger={<div>
                        <Avatar icon={'account'} size={"small"}/>
                        <span style={{marginLeft: 10}}>{user?.nickname}</span>
                    </div>}
                    triggerType={["click", "hover"]}
                    afterOpen={() => console.log("after open")}
                >
                    <Menu>
                        {/*<Menu.Item>修改密码</Menu.Item>*/}
                        <Menu.Item onClick={() => {
                            logout(() => {
                                window.location.href = window.location.origin;
                            })
                        }}>退出系统</Menu.Item>
                    </Menu>
                </Dropdown>
            </Shell.Action>

            <Shell.Navigation
                trigger={null}
                collapse={navcollapse}
                onCollapseChange={onNavCollapseChange}
                style={{padding: 0}}
            >
                <NavToggle onClick={() => {
                    setNavcollapse(!navcollapse)
                }}>
                    {navcollapse ? (
                        <Icon type="toggle-right" size="xs"/>
                    ) : (
                        <Icon type="toggle-left" size="xs"/>
                    )}
                </NavToggle>

                <Nav embeddable aria-label="global navigation" defaultSelectedKeys={selectedNavPaths}>
                    <Nav.Item icon={<FaIcon icon={'home'} style={{marginRight: '4px'}}/>} key={`/overview`}>
                        <NavLink to={`/overview`}>首页</NavLink>
                    </Nav.Item>

                    <Nav.SubNav icon={<FaIcon icon={'registered'} style={{marginRight: '4px'}}/>} label="用户注册">
                        <Nav.Item icon="account">
                            <NavLink to={'/member-management'}>用户管理</NavLink>
                        </Nav.Item>
                    </Nav.SubNav>

                    <Nav.SubNav icon={<FaIcon icon={'running'} style={{marginRight: '4px'}}/>} label="步道管理">
                        <Nav.Item icon={<FaIcon icon={'map'} style={{marginRight: '4px'}}/>}>
                            <NavLink to={'/footpath-management'}>步道线路管理</NavLink>
                        </Nav.Item>
                        <Nav.Item icon={<FaIcon icon={'book'} style={{marginRight: '4px'}}/>}>
                            <NavLink to={'/run-stat-d'}>每天跑步记录</NavLink>
                        </Nav.Item>
                    </Nav.SubNav>

                    <Nav.SubNav icon={<FaIcon icon="rss" style={{marginRight: '4px'}}/>} label="发布管理">
                        <Nav.Item icon={<FaIcon icon={'info'} style={{marginRight: '4px'}}/>}>
                            <NavLink to={'/information-management'}>资讯管理</NavLink>
                        </Nav.Item>
                    </Nav.SubNav>
                </Nav>
            </Shell.Navigation>

            <Shell.Content style={{padding: 0, width: "100%", backgroundColor: '#fff'}}>
                <ContentContainer>
                    <Routes>
                        <Route path={'/overview'} element={<Overview/>}></Route>
                        <Route path={'/member-management'} element={<MemberManagement/>}></Route>
                        <Route path={'/member-management/:memberId'} element={<MemberDetail/>}></Route>
                        <Route path={'/footpath-management'} element={<FootpathManagement/>}></Route>
                        <Route path={'/footpath-management/:footpathId'} element={<FootpathDetail/>}></Route>
                        <Route path={'/run-stat-d'} element={<RunStatD/>}></Route>
                        <Route path={'/capture-log/:statId'} element={<CapureDetail/>}></Route>
                        <Route path={'/information-management'} element={<InformationManagement/>}></Route>
                        <Route path={"*"} element={<NotFound/>}/>
                        <Navigate to={`${window.location.pathname}/overview`} replace={true}/>
                    </Routes>
                </ContentContainer>
            </Shell.Content>

        </Shell>

    )
};

const NavToggle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
`

const ContentContainer = styled.div`
    min-height: calc(100vh - 54px);
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #fff;
`

export default AuthenticatedApp