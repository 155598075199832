import React from "react";
import styled from "@emotion/styled";
import {Box, Dialog, Typography} from "@alifd/next";

const FullPage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const isError = (value: any): value is Error  => {
    return value?.message
};

export const ErrorBox = ({error}: {error: unknown}) => {
    if (isError(error)) {
        return (
            <Typography.Text>{error.message}</Typography.Text>
        )
    }
    return null
};

export const PageContainer = styled(Box)`
    padding: 1rem;
    flex: 1;
    background-color: #ffffff;
`;

export const PaginationLBContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1rem;
    flex: 1;
`;