import React from "react";
import {Field, Form, Input, Message, Select} from "@alifd/next";
import {useSaveInformation} from "../../../models/public-center/information.model";
import {Information} from "../../../types/public-center/Information";
import {openErrorNotification} from "../../../utils/notificationUtil";
import DialogContainer from "../../../components/DialogContainer";

export interface AddInformationDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddInformationDialog: React.FC<AddInformationDialogProps> = (props) => {
    const {visible, toggleVisible, onSuccess} = props
    const field = Field.useField();
    const {mutateAsync} = useSaveInformation();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const user = field.getValues<Partial<Information>>();
            mutateAsync(user).then(() => {
                Message.success("新建资讯成功！");
                onSuccess();
                handleClose()
            }).catch(reason => {
                openErrorNotification("新建资讯失败", reason.message);
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加资讯'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="标题：" required={true}>
                    <Input name={'title'} />
                </Form.Item>
                <Form.Item label="二级标题：" required={true}>
                    <Input name={'subtitle'} />
                </Form.Item>
                <Form.Item label="发布时间：" required={true}>
                    <Input name="releaseTime" />
                </Form.Item>
                <Form.Item label="发布渠道：" required={true}>
                    <Input name="publishChannel" />
                </Form.Item>
                <Form.Item label="状态：" required={true}>
                    <Input name="status" />
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddInformationDialog