import React, {useEffect} from "react";
import {
    Box,
    Breadcrumb,
    Button, Divider,
    Field,
    Form,
    Grid,
    Icon,
    Input,
    Loading,
    Message,
    Pagination, Select,
    Tab,
    Table
} from "@alifd/next";
import * as footpathModel from "models/srms/footpath.model";
import {useURLPathParams} from "../../hooks/useURLPathParams";
import {Footpath} from "../../types/srms/Footpath";
import {openErrorNotification} from "../../utils/notificationUtil";
import {PageContainer, PaginationLBContainer} from "components/lib";
import {defaultFormItemLayout} from "../../contants/Constans";
import {Link} from "react-router-dom";

const CapureDetail: React.FC = (props) => {
    const pathParams = useURLPathParams("/capture-log/:statId", ["statId"]);
    const statId = pathParams?.statId;

    const {data, isLoading} = footpathModel.useGetFootpathById(statId);
    const {mutateAsync: updateUserMutateAsync} = footpathModel.useUpdateFootpath();

    const footpathField = Field.useField({forceUpdate: false});

    useEffect(() => {
        footpathField.setValues(data)
    }, [data]);

    function handleSubmitUserInfo() {
        footpathField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const user = footpathField.getValues<Partial<Footpath>>();
            updateUserMutateAsync(user).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    return isLoading ? (<Loading fullScreen={true}/>) : (
        <PageContainer>
            <Breadcrumb>
                <Breadcrumb.Item link="javascript:void(0);">抓拍记录</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <b>{statId}</b>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{marginTop: '1rem'}}>

                <Table dataSource={[]} loading={isLoading}>
                    <Table.Column title="ID" dataIndex="id" />
                    <Table.Column title="抓拍人脸" dataIndex="name" />
                    <Table.Column title="抓拍时间" dataIndex="totalLength" />
                    <Table.Column title="速度" dataIndex="xxx" />
                    <Table.Column title="摄像距离" dataIndex="createdTime" />
                </Table>
                <PaginationLBContainer>
                    <Pagination />
                </PaginationLBContainer>

            </div>

        </PageContainer>
    )
}

export default CapureDetail