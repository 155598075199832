import React, {useEffect} from "react";
import {
    Box,
    Breadcrumb,
    Button, Divider,
    Field,
    Form,
    Grid,
    Icon,
    Input,
    Loading,
    Message,
    Pagination,
    Tab,
    Table
} from "@alifd/next";
import * as footpathModel from "models/srms/footpath.model";
import {useURLPathParams} from "../../hooks/useURLPathParams";
import {Footpath} from "../../types/srms/Footpath";
import {openErrorNotification} from "../../utils/notificationUtil";
import {PageContainer, PaginationLBContainer} from "components/lib";
import {defaultFormItemLayout} from "../../contants/Constans";
import {Link} from "react-router-dom";

function renderAction(value: any, index: number, record: Footpath) {

    return <Box direction={"row"} justify={"flex-start"}>
        <Button text={true}>编辑</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>禁用</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>删除</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>
            <Link to={`/footpath-management/${record.id}`} state={record}>详情</Link>
        </Button>
    </Box>
}

const FootpathDetail: React.FC = (props) => {
    const pathParams = useURLPathParams("/footpath-management/:footpathId", ["footpathId"]);
    const footpathId = pathParams?.footpathId;

    const {data, isLoading} = footpathModel.useGetFootpathById(footpathId);
    const {mutateAsync: updateUserMutateAsync} = footpathModel.useUpdateFootpath();

    const footpathField = Field.useField({forceUpdate: false});

    useEffect(() => {
        footpathField.setValues(data)
    }, [data]);

    function handleSubmitUserInfo() {
        footpathField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const user = footpathField.getValues<Partial<Footpath>>();
            updateUserMutateAsync(user).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    return isLoading ? (<Loading fullScreen={true}/>) : (
        <PageContainer>
            <Breadcrumb>
                <Breadcrumb.Item link="javascript:void(0);">步道管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <b>{footpathId}</b>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Box direction={"row"} justify={"flex-start"} align={"center"} spacing={10} style={{marginTop: '1rem'}}>
                {/*<Icon size={"xs"} type="arrow-left" />*/}
                <label>{footpathId}</label>
                <Icon type="ashbin"/>
            </Box>

            <Tab>
                <Tab.Item title="步道信息" key="1">
                    <div style={{width: '80rem', paddingTop: '2rem'}}>
                        <Form {...defaultFormItemLayout} colon={true} field={footpathField}>
                            <Form.Item label="步道名称：" required={true} isPreview={true}>
                                <Input name={'name'} />
                            </Form.Item>
                            <Form.Item label="总长度（米）：" required={true}>
                                <Input name="totalLength"/>
                            </Form.Item>
                            <Form.Item label="选择位置：">
                                <Input name="loc"/>
                            </Form.Item>
                            <Form.Item label="状态：">
                                <Input name="status"/>
                            </Form.Item>
                        </Form>

                        <Grid.Row>
                            <Grid.Col span="6"></Grid.Col>
                            <Grid.Col span="18">
                                <Button type={"primary"} onClick={handleSubmitUserInfo}>提交</Button>
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                </Tab.Item>
                <Tab.Item title="步道平面图" key="2">
                    步道平面图
                </Tab.Item>
                <Tab.Item title="抓拍设备" key="3">
                    <div style={{width: '80rem', paddingTop: '1rem'}}>
                        <Box direction={"row"} justify={"space-between"} align={"center"} style={{marginBottom: '1rem'}}>
                            <Box direction={"row"} spacing={10}>
                                <Button type={"primary"}>添加设备</Button>
                                <Button>刷新</Button>
                            </Box>
                        </Box>
                        <Table dataSource={[]} loading={isLoading}>
                            <Table.Column title="ID" dataIndex="id" />
                            <Table.Column title="设备名称" dataIndex="name" />
                            <Table.Column title="设备ID" dataIndex="totalLength" />
                            <Table.Column title="秘钥" dataIndex="loc" />
                            <Table.Column title="控制杆ID" dataIndex="xxx" />
                            <Table.Column title="经纬度" dataIndex="xxx" />
                            <Table.Column title="创建时间" dataIndex="createdTime" />
                            <Table.Column title="操作" cell={renderAction} />
                        </Table>
                        <PaginationLBContainer>
                            <Pagination />
                        </PaginationLBContainer>
                    </div>
                </Tab.Item>
            </Tab>
        </PageContainer>
    )
}

export default FootpathDetail