export const isTrue = (value: unknown) => value === 0 ? true : !!value
export const isFalsy = (value: unknown) => !isTrue(value)
export const isVoid = (value: unknown) => value === undefined || value === null || value === ''

export const cleanObjectNullValue = (o: {[key: string]: unknown}) => {
    const result = Object.assign({}, o)
    Object.keys(o).forEach(key => {
        const value = o[key]
        if (isVoid(value)) {
            delete result[key]
        }
    })
    return result
}

const str = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789";

export function randomString(randomLen?: number) {
    const len = randomLen || 32;
    let result: string = "";
    for (let i = 0; i < len; i++) {
        result += str.charAt(Math.floor(Math.random() * str.length));
    }
    return result
}

export const resetRoute = () => {
    window.location.href = window.location.origin
}