import axios, {
    AxiosError,
    AxiosInstance,
    AxiosInterceptorManager,
    AxiosRequestConfig,
    AxiosResponse,
    ResponseType
} from "axios";
import JSONBig from "json-bigint";
import {blobToJson, createObjectURL} from "./util";
import {ErrorResponse, HttpError} from "./types";

// 默认配置
const DEFAULT_CONFIG = {
    withCredentials: true, // 跨域请求时携带cookie
    // timeout: 10000, // 如果请求话费了超过 `timeout` 的时间，请求将被中断
    /*transformRequest: (data: any, headers?: any) => {
        // 修改请求数据
        return data;
    },*/
    transformResponse: (data: any, headers?: any) => {
        // 修改响应数据
        try {
            if (data) {
                const result = JSONBig.parse(data);
                return result
            }
            return data;
        } catch (e) {
        }
        return data
    }
}

export class HttpClient {
    private readonly _config: AxiosRequestConfig;
    private readonly _instance: AxiosInstance;

    public interceptors: {
        request: AxiosInterceptorManager<AxiosRequestConfig>;
        response: AxiosInterceptorManager<AxiosResponse>
    };

    constructor(config?: AxiosRequestConfig) {

        this._config = Object.assign({}, DEFAULT_CONFIG, config);

        this._instance = axios.create(this._config);

        this.interceptors = this._instance.interceptors;

        this._instance.interceptors.request.use(function (requestConfig) {
            // 在发送请求之前处理
            /*const uri = requestConfig.url?.replace(requestConfig.baseURL || '', '');
            const commonHeaders = {
                // 'timestamp': new Date().getTime(),
                'source': 'web',
                // 'uri': uri
            };
            const headers = Object.assign(commonHeaders, requestConfig.headers);
            const sign = md5(JSONBig.stringify(headers));
            requestConfig.headers = Object.assign(headers, {"sign": sign});*/
            return requestConfig;
        });

        this._instance.interceptors.response.use(function (response) {
            // 对响应数据处理
            const responseData = response.data;
            /*
            if (response.headers["content-type"] === "application/json") {
                if (isRestResponse(responseData)) {
                    if (!responseData.success) {
                        return Promise.reject(new ResponseError("ResponseError", responseData.message, responseData.status))
                    } else {
                        return Promise.resolve(responseData)
                    }
                }
            }
            */
            return Promise.resolve(responseData);
            // return response;
        }, async function (error: AxiosError) {
            // 对响应错误处理
            const url = error.config.url || '';
            let errorResponse: ErrorResponse | undefined = undefined;
            if (error.response) {
                const errorResponseData = error.response.data;
                errorResponse = errorResponseData;
                const responseType: ResponseType | undefined = error.response.config.responseType;
                if (responseType === "blob") {
                    errorResponse = await blobToJson(errorResponseData);
                }
            }

            return Promise.reject(new HttpError(url, error, errorResponse));
        });

    }

    async request<T>(requestConfig: AxiosRequestConfig): Promise<T> {
        return this._instance.request<T, T>(requestConfig).then((response) => {
            return Promise.resolve(response)
        })
    }

    async get<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
        return this._instance.get<T, T>(url, requestConfig)
            .then((response) => {
                return Promise.resolve(response);
            })
    }

    async delete<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
        return this._instance.delete<T, T>(url, requestConfig).then((response) => {
            return Promise.resolve(response);
        })
    }

    async post<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
        let data = requestConfig?.data;
        return this._instance.post<T, T>(url, data, requestConfig).then((response?) => {
            return Promise.resolve(response);
        })
    }

    async put<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
        let data = requestConfig?.data;
        return this._instance.put<T, T>(url, data, requestConfig).then((response) => {
            return Promise.resolve(response);
        })
    }

    async patch<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
        let data = requestConfig?.data;
        return this._instance.patch<T, T>(url, data, requestConfig).then((response) => {
            return Promise.resolve(response);
        })
    }

    // 上传文件
    async uploadFile<T>(url: string, formData: FormData, requestConfig?: Omit<AxiosRequestConfig, "url" | "data">): Promise<T> {
        const _config = Object.assign({
            onUploadProgress: (progressEvent: any) => {
                const {loaded, total} = progressEvent;
                let p = (loaded / total * 100).toFixed(0) + '%';
                console.log(p)
            }
        }, requestConfig);

        return this.post<T>(url, {
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            ..._config
        });
    };

    // 下载文件
    async downloadFile(url: string, filename: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this._instance.get<Blob, Blob>(
            url,
            {
                responseType: "blob"
            }
        ).then((response) => {
            const blob = new Blob([response]);
            const objectURL = createObjectURL(blob);
            let link: HTMLAnchorElement | null = document.createElement('a');
            link.download = filename;
            link.href = objectURL;
            link.click();
            URL.revokeObjectURL(objectURL);
            link = null;
            return Promise.resolve()
        })

    }

    // 预览文件
    async previewFile(url: string, requestConfig?: AxiosRequestConfig): Promise<void> {
        return this.get<Blob>(
            url,
            {
                ...requestConfig,
                responseType: "blob"
            }
        ).then((response) => {
            // 需要指定文件类型，谷歌浏览器不能打开xlsx、doc等
            const blob = new Blob([response], {
                type: response.type
            });

            // 新窗口打开
            const link = document.createElement('a');
            link.href = createObjectURL(blob);
            link.target = "_blank";
            link.click();

            return Promise.resolve()
        })

    }

}