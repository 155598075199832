import {useMutation, useQuery, useQueryClient} from "react-query";
import * as runStatDService from "services/srms/runstatd.service";
import {HttpError} from "../../httpclient";
import {openErrorNotification} from "../../utils/notificationUtil";
import {PagedList} from "../../types/core/PagedList";
import {useCallback} from "react";
import {RunStatDay} from "../../types/srms/RunStatDay";

export const PAGE_LIST_CACHE_KEY = "InformationPageList";

export function useGetFootpathById(id: string | undefined) {
    return useQuery<RunStatDay, HttpError>(['GetInformationById', id], () => {
        return runStatDService.getFootpathById(id);
    }, {
        enabled: !!id,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}


export function useFootpathPageList(current: number, pageSize: number) {
    return useQuery<PagedList<RunStatDay>, HttpError>([PAGE_LIST_CACHE_KEY, current, pageSize], function () {
        return runStatDService.findFootpathPageList(current, pageSize);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useSaveFootpath() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<RunStatDay>) {
        return runStatDService.saveFootpath(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateFootpath() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<RunStatDay>) {
        return runStatDService.updateFootpath(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteFootpath() {
    const queryClient = useQueryClient();
    return useMutation(async function(id: string) {
        return runStatDService.deleteFootpath(id)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useRefresh() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
    }, [])
}