import {useMutation, useQuery, useQueryClient} from "react-query";
import * as informationService from "services/public-center/information.service";
import {HttpError} from "../../httpclient";
import {openErrorNotification} from "../../utils/notificationUtil";
import {PagedList} from "../../types/core/PagedList";
import {Information} from "../../types/public-center/Information";
import {useCallback} from "react";

export const PAGE_LIST_CACHE_KEY = "InformationPageList";

export function useGetInformationById(userId: string | undefined) {
    return useQuery<Information, HttpError>(['GetInformationById', userId], () => {
        return informationService.getFootpathById(userId);
    }, {
        enabled: !!userId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}


export function useFindInformationPageList(current: number, pageSize: number) {
    return useQuery<PagedList<Information>, HttpError>([PAGE_LIST_CACHE_KEY, current, pageSize], function () {
        return informationService.findInformationPageList(current, pageSize);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useSaveInformation() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<Information>) {
        return informationService.saveInformation(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateInformation() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<Information>) {
        return informationService.updateInformation(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteInformation() {
    const queryClient = useQueryClient();
    return useMutation(async function(id: string) {
        return informationService.deleteInformation(id)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useRefresh() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
    }, [])
}