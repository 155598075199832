import {useMutation, useQuery, useQueryClient} from "react-query";
import * as footpathService from "services/srms/footpath.service";
import {HttpError} from "../../httpclient";
import {openErrorNotification} from "../../utils/notificationUtil";
import {PagedList} from "../../types/core/PagedList";
import {useCallback} from "react";
import {Footpath} from "../../types/srms/Footpath";

export const PAGE_LIST_CACHE_KEY = "InformationPageList";

export function useGetFootpathById(id: string | undefined) {
    return useQuery<Footpath, HttpError>(['GetInformationById', id], () => {
        return footpathService.getFootpathById(id);
    }, {
        enabled: !!id,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}


export function useFootpathPageList(current: number, pageSize: number) {
    return useQuery<PagedList<Footpath>, HttpError>([PAGE_LIST_CACHE_KEY, current, pageSize], function () {
        return footpathService.findFootpathPageList(current, pageSize);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useSaveFootpath() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<Footpath>) {
        return footpathService.saveFootpath(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateFootpath() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<Footpath>) {
        return footpathService.updateFootpath(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteFootpath() {
    const queryClient = useQueryClient();
    return useMutation(async function(id: string) {
        return footpathService.deleteFootpath(id)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useRefresh() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
    }, [])
}