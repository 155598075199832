import {Box, Button, Form, Input, Pagination, Select, Table} from "@alifd/next";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import * as memberModel from "../../models/member-center/member.model";
import {PageContainer, PaginationLBContainer} from "components/lib";
import {Member} from "../../types/member-center/Member";
import FaIcon from "../../components/FaIcon";

function renderAction(value: any, index: number, record: Member) {

    return <Box direction={"row"} justify={"flex-start"}>
        {/*<Button text={true}>禁用</Button>
        <Divider direction={"ver"}/>*/}
        <Button text={true}>
            <Link to={`/member-management/${record.id}`} state={record}>详情</Link>
        </Button>
    </Box>
}

const MemberManagement = () => {
    const [current, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const {data: pageList, isLoading} = memberModel.useFindMemberPageList(current, pageSize);
    const refresh = memberModel.useRefresh();

    async function handleRefresh() {
        await refresh()
    }

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <label>会员管理</label>
                <Box direction={"row"} spacing={10}>
                    <Button onClick={handleRefresh}>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true}>
                    <Form.Item label="用户类型">
                        <Select>
                            <Select.Option value="1">微信用户</Select.Option>
                            <Select.Option value="0">临时用户</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="注册来源">
                        <Select>
                            <Select.Option value="program">小程序</Select.Option>
                            <Select.Option value="client">公园终端</Select.Option>
                            <Select.Option value="share">微信分享</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="标题">
                        <Input />
                    </Form.Item>
                    <Form.Submit type={"primary"}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading}>
                <Table.Column title="ID" dataIndex="id" />
                <Table.Column title="微信ID" dataIndex="openId" />
                <Table.Column title="昵称" dataIndex="nickname" />
                <Table.Column title="身高" dataIndex="height" />
                <Table.Column title="体重" dataIndex="weight" />
                <Table.Column title="头像" dataIndex="avatar" />
                <Table.Column title="血压高(mmHg)" dataIndex="bpHigh" />
                <Table.Column title="血压低(mmHg)" dataIndex="bpLow" />
                <Table.Column title="用户类型" dataIndex="userType" />
                <Table.Column title="注册来源" dataIndex="source" />
                <Table.Column title="注册时间" dataIndex="createdTime" />
                <Table.Column title="状态" />
                <Table.Column title="操作" cell={renderAction} />
            </Table>
            <PaginationLBContainer>
                <Pagination />
            </PaginationLBContainer>

        </PageContainer>
    )
}

export default MemberManagement