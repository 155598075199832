import React from "react";
import {Field, Form, Input, Message, Select} from "@alifd/next";
import {useSaveFootpath} from "models/srms/footpath.model";
import {Footpath} from "../../../types/srms/Footpath";
import {openErrorNotification} from "../../../utils/notificationUtil";
import DialogContainer from "../../../components/DialogContainer";

export interface AddUserDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddFootpathDialog: React.FC<AddUserDialogProps> = (props) => {
    const {visible, toggleVisible, onSuccess} = props
    const field = Field.useField();
    const {mutateAsync} = useSaveFootpath();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const user = field.getValues<Partial<Footpath>>();
            mutateAsync(user).then(() => {
                Message.success("新建步道成功！");
                onSuccess();
                handleClose()
            }).catch(reason => {
                openErrorNotification("新建步道失败", reason.message);
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加步道'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="步道名称：" required={true}>
                    <Input name={'username'} />
                </Form.Item>
                <Form.Item label="步道总长度（米）：" required={true}>
                    <Input name={'password'} />
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddFootpathDialog