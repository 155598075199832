import React from "react";
import {PageContainer} from "../components/lib";

const Overview: React.FC = () => {
    return (
        <PageContainer direction={"row"} justify={"center"} align={"center"}>
            欢迎使用智慧步道管理系统
        </PageContainer>
    )
}

export default Overview