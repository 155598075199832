import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from '@alifd/next';
import {UAAProvider} from "./UAAContext";
import uaaClient from "../utils/uaaClient";
import ServerError from "../components/ServerError";

export const AppProviders = ({children}: { children: React.ReactNode }) => {

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
                refetchOnWindowFocus: false
            }
        }
    });

    return <ConfigProvider errorBoundary={{fallbackUI: (error: Error, errorInfo: React.ErrorInfo) => {
            return <ServerError />
        }, afterCatch: (error: Error, errorInfo: React.ErrorInfo) => {
            // console.log(error.message)
        } }}>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <UAAProvider client={uaaClient}>
                    {children}
                </UAAProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </ConfigProvider>

    /*return <UseRequestProvider value={{
        refreshOnWindowFocus: false,
        requestMethod: (service: any) => {
            switch (typeof service) {
                case "string":
                    return request(service)
                case "object":
                    const {url, ...options} = service
                    return request(url, options)
                default:
                    throw new Error(`service 参数错误：${service}`)
            }
        }
    }}>
        <BrowserRouter>
            <ReduxProvider store={store}>
                <QueryClientProvider client={queryClient}>
                    <SecurityProvider>
                        {children}
                    </SecurityProvider>
                </QueryClientProvider>
            </ReduxProvider>
        </BrowserRouter>
    </UseRequestProvider>*/
}