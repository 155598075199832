import React from "react";
import uaaClient from "./utils/uaaClient";

const UnauthenticatedApp = () => {
    uaaClient.login();
    return (
        <React.Fragment />
    )
}

export default UnauthenticatedApp