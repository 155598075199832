import React, {useState} from "react";
import {Box, Button, Divider, Form, Input, Pagination, Select, Table} from "@alifd/next";
import {Link} from "react-router-dom";
import * as footpathModel from "models/srms/footpath.model";
import AddFootpathDialog from "./components/AddFootpathDialog";
import {useToggle} from "react-use";
import {Footpath} from "../../types/srms/Footpath";
import {PageContainer, PaginationLBContainer} from "../../components/lib";

function renderAction(value: any, index: number, record: Footpath) {

    return <Box direction={"row"} justify={"flex-start"}>
        <Button text={true}>编辑</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>禁用</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>删除</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>
            <Link to={`/footpath-management/${record.id}`} state={record}>详情</Link>
        </Button>
    </Box>
}

const FootpathManagement = () => {
    const [current, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const {data: pageList, isLoading} = footpathModel.useFootpathPageList(current, pageSize);

    const [addDialogVisible, toggleDialogVisible] = useToggle(false);

    function onAddSuccess() {
    }

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <label>步道管理</label>
                <Box direction={"row"} spacing={10}>
                    <Button type={"primary"} onClick={toggleDialogVisible}>添加步道</Button>
                    <Button>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true}>
                    <Form.Item label="步道状态">
                        <Select>
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="0">禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="步道名称">
                        <Input />
                    </Form.Item>
                    <Form.Submit type={"primary"}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading}>
                <Table.Column title="ID" dataIndex="id" />
                <Table.Column title="步道名称" dataIndex="name" />
                <Table.Column title="步道总长度（米）" dataIndex="totalLength" />
                <Table.Column title="位置" dataIndex="loc" />
                <Table.Column title="创建时间" dataIndex="createdTime" />
                <Table.Column title="操作" cell={renderAction} />
            </Table>
            <PaginationLBContainer>
                <Pagination />
            </PaginationLBContainer>

            <AddFootpathDialog visible={addDialogVisible} toggleVisible={toggleDialogVisible} onSuccess={onAddSuccess} />

        </PageContainer>
    )
}

export default FootpathManagement