import React, {useState} from "react";
import {Box, Button, DatePicker, Form, Input, Pagination, Select, Table} from "@alifd/next";
import {Link} from "react-router-dom";
import * as runStatDModel from "models/srms/runstatd.model";
import {PageContainer, PaginationLBContainer} from "components/lib";
import {RunStatDay} from "../../types/srms/RunStatDay";

function renderAction(value: any, index: number, record: RunStatDay) {
    return <Box direction={"row"} justify={"flex-start"}>
        <Button text={true}>
            <Link to={`/capture-log/${record.id}`} state={record}>抓拍记录</Link>
        </Button>
    </Box>
}

const RunStatD = () => {
    const [current, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const {data: pageList, isLoading} = runStatDModel.useFootpathPageList(current, pageSize);

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <label>跑步记录</label>
                <Box direction={"row"} spacing={10}>
                    <Button>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true}>
                    <Form.Item label="记录天">
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label="微信ID">
                        <Input />
                    </Form.Item>
                    <Form.Submit type={"primary"}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading}>
                <Table.Column title="ID" dataIndex="id" />
                <Table.Column title="微信ID" dataIndex="openId" />
                <Table.Column title="昵称" dataIndex="name" />
                <Table.Column title="头像" dataIndex="totalLength" />
                <Table.Column title="记录天" dataIndex="loc" />
                <Table.Column title="开始时间" dataIndex="createdTime" />
                <Table.Column title="结束时间" dataIndex="createdTime" />
                <Table.Column title="总里程" dataIndex="totalLength" />
                <Table.Column title="总时长" dataIndex="createdTime" />
                <Table.Column title="操作" cell={renderAction} />
            </Table>
            <PaginationLBContainer>
                <Pagination />
            </PaginationLBContainer>

        </PageContainer>
    )
}

export default RunStatD