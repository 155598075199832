import {useMutation, useQuery, useQueryClient} from "react-query";
import * as informationService from "services/member-center/member.service";
import {HttpError} from "../../httpclient";
import {openErrorNotification} from "../../utils/notificationUtil";
import {PagedList} from "../../types/core/PagedList";
import {Information} from "../../types/public-center/Information";
import {useCallback} from "react";
import {Member} from "../../types/member-center/Member";

export const PAGE_LIST_CACHE_KEY = "MemberPageList";

export function useFindMemberPageList(current: number, pageSize: number) {
    return useQuery<PagedList<Member>, HttpError>([PAGE_LIST_CACHE_KEY, current, pageSize], function () {
        return informationService.findInformationPageList(current, pageSize);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useGetMemberById(id: string | undefined) {
    return useQuery<Member, HttpError>(['GetMemberById', id], () => {
        return informationService.getMemberById(id);
    }, {
        enabled: !!id,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useUpdateMember() {
    const queryClient = useQueryClient();
    return useMutation(async function(member: Partial<Information>) {
        return informationService.updateMember(member)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useRefresh() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
    }, [])
}