import {Box, Button, Divider, Form, Input, Pagination, Select, Table} from "@alifd/next";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {Information} from "../../types/public-center/Information";
import * as informationModel from "../../models/public-center/information.model";
import {useToggle} from "react-use";
import { PageContainer, PaginationLBContainer } from "components/lib";
import AddInformationDialog from "./components/AddInformationDialog";

function renderAction(value: any, index: number, record: Information) {

    return <Box direction={"row"} justify={"flex-start"}>
        <Button text={true}>编辑</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>禁用</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>删除</Button>
        <Divider direction={"ver"}/>
        <Button text={true}>
            <Link to={`/information-management/${record.id}`} state={record}>详情</Link>
        </Button>
    </Box>
}

const InformationManagement = () => {
    const [current, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const {data: pageList, isLoading} = informationModel.useFindInformationPageList(current, pageSize);
    const refresh = informationModel.useRefresh();

    const [addDialogVisible, toggleDialogVisible] = useToggle(false);

    function onAddSuccess() {
    }

    function handleRefresh() {
        refresh()
    }

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <label>资讯管理</label>
                <Box direction={"row"} spacing={10}>
                    <Button type={"primary"} onClick={toggleDialogVisible}>添加资讯</Button>
                    <Button onClick={handleRefresh}>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true}>
                    <Form.Item label="状态">
                        <Select>
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="0">禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="发布渠道">
                        <Select>
                            <Select.Option value="xxx">公园互动屏</Select.Option>
                            <Select.Option value="program">小程序</Select.Option>
                            <Select.Option value="datav">可视化大屏</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="标题">
                        <Input />
                    </Form.Item>
                    <Form.Submit type={"primary"}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading}>
                <Table.Column title="ID" dataIndex="id" />
                <Table.Column title="标题" dataIndex="title" />
                <Table.Column title="二级标题" dataIndex="subtitle" />
                <Table.Column title="发布时间" dataIndex="releaseTime" />
                <Table.Column title="发布渠道" dataIndex="publishChannel" />
                <Table.Column title="状态" />
                <Table.Column title="创建时间" dataIndex="createdTime" />
                <Table.Column title="操作" cell={renderAction} />
            </Table>
            <PaginationLBContainer>
                <Pagination />
            </PaginationLBContainer>

            <AddInformationDialog visible={addDialogVisible} toggleVisible={toggleDialogVisible} onSuccess={onAddSuccess} />

        </PageContainer>
    )
}

export default InformationManagement