import {PagedList} from "../../types/core/PagedList";
import httpClient from "../../utils/httpClient";
import {RunStatDay} from "../../types/srms/RunStatDay";

const isLocalAPI = process.env.REACT_APP_LOCAL_API;
let SERVICE_NAME = "/srms";
if (isLocalAPI === "true") {
    SERVICE_NAME = "";
}

export async function findFootpathPageList(current: number, pageSize: number) {
    return httpClient.get<PagedList<RunStatDay>>(`${SERVICE_NAME}/api/v1/footpath/findPageList`, {
        params: {
            current,
            pageSize
        }
    })
}

export function getFootpathById(id: string | undefined) {
    return httpClient.get<RunStatDay>(`${SERVICE_NAME}/api/v1/footpath/${id}`)
}

export async function saveFootpath(footpath: Partial<RunStatDay>) {
    return httpClient.post('${SERVICE_NAME}/api/v1/footpath', {
        data: footpath
    });
}

export async function updateFootpath(footpath: Partial<RunStatDay>) {
    return httpClient.put(`${SERVICE_NAME}/api/v1/footpath/${footpath.id}`, {
        data: footpath
    });
}

export async function deleteFootpath(id: string | undefined) {
    return httpClient.delete(`${SERVICE_NAME}/api/v1/footpath/${id}`);
}